<template>
	<div class="animated fadeIn">
		<b-card>
			<div class="d-flex justify-content-between">
				<strong class="card-title mb-1">{{$route.meta.label}}</strong>
				<b-button class="btn btn-primary text-white rounded" style="height: fit-content" :to="'/'+config.uri+'/add'">
					<i class="fa fa-plus"/> Add Article
				</b-button>
			</div>
			<div class="mt-2">
				<p class="font-sm font-weight-bold mb-2" style="cursor: pointer;">How to contribute?</p>
				<ol class="px-3 w-100">
					<li class="font-sm mb-2 text-justify">Ajukan artikel yang Anda buat dengan cara klik tombol <span class="font-weight-bold font-italic">Add Article</span></li>
					<li class="font-sm mb-2 text-justify">Tunggu artikel yang Anda ajukan direview oleh Tim Harian Analisa dengan status <span class="font-weight-bold font-italic">Waiting Review</span></li>
					<li class="font-sm mb-2 text-justify">Artikel Anda akan dipublikasikan jika status artikel sudah berubah menjadi <span class="font-weight-bold font-italic">Publish</span></li>
				</ol>
			</div>
			<b-row class="justify-content-end mb-10">
				<ResponseAlert ref="response" />
				<b-col sm="5">
					<b-form-select class="col-sm-4" v-model="config.additional_params.category_id" :options="masters.categories"></b-form-select>
					<div class="col-sm-7 pull-right no-padding">
						<div class="input-group">
							<input type="text" placeholder="Search title" v-model="config.search.key" v-on:keyup.enter="get()"
								class="form-control form-control-sm" />
							<div class="input-group-prepend">
								<div class="input-group-text" v-on:click="get()">
									<i class="fa fa-search"></i>
								</div>
							</div>
						</div>
					</div>
				</b-col>
			</b-row>
			<b-row>
				<b-col sm="12">
					<div class="table-responsive">
						<table class="table b-table">
							<thead>
								<tr>
									<th v-for="(row, key) in config.headers" :width="row.width" :key="key"
										:class="row.class">
										<a v-if="row.value" class="sort dark-font" v-on:click="sort(row.value)"
											href="javascript:void(0)">
											{{row.title}}
											<i :id="row.value" class="fa fa-sort"></i>
										</a>
										<a v-else class="sort dark-font" :id="row.value" href="javascript:void(0)">
											{{row.title}}
										</a>
									</th>
								</tr>
							</thead>
							<tbody>
								<template v-if="config.total_data">
									<tr v-for="(row, key) in config.rows" :key="key">
										<td>{{row.title}}</td>
										<td>{{vars.moment(row.date).format('DD MMM YYYY HH:mm')}}</td>
										<td>{{row.category && row.category.name}}</td>
										<td>{{row.total_read}}</td>
										<td>
											<span :class="'badge badge-' + getClassStatus(row.status)">
												{{row.status}}
											</span>
										</td>
										<td align="center">
											<router-link :to='`/${config.uri}/${row.id}`' title="Detail"
												class="btn-action">
												<i class="fa fa-eye"></i>
											</router-link>
											<router-link v-if="row.status === 'waiting review'" :to='`/${config.uri}/edit/${row.id}`' title="Edit"
												class="btn-action">
												<i class="fa fa-pencil"></i>
											</router-link>
											<button v-if="row.status === 'waiting review'" @click="destroy(row.id)" title="Delete" class="btn-action">
												<i class="fa fa-trash"></i>
											</button>
										</td>
									</tr>
								</template>
								<tr v-if="!config.total_data">
									<td :colspan="config.headers.length" align="center"> No data available. </td>
								</tr>
							</tbody>
							<tfoot v-if="config.total_data">
								<tr>
									<td :colspan="config.headers.length" class="no-padding">
										<div class="pull-left table-information mt-25">
											<p>Showing {{config.page}} to {{config.total_page}} of {{config.total_data}}
												entries</p>
										</div>
										<div class="pull-right pagination mt-25">
											<b-pagination @change="gotoPage" :total-rows="config.total_data"
												v-model="config.page" :per-page="config.per_page">
											</b-pagination>
										</div>
									</td>
								</tr>
							</tfoot>
						</table>
					</div>
				</b-col>
			</b-row>
		</b-card>
	</div>
</template>
<script>
	import moment from 'moment'
	import table from '@/utils/table.js'
	import api from '@/utils/api.js'
	import ResponseAlert from '@/components/response_alert';
	import { getClassStatus } from '@/utils/helpers';
	import { mapState } from 'vuex';
	export default {
		name: 'ListVisitorArticle',
		components: {
			ResponseAlert,
		},
		data() {
			return {
				config: {
					uri: this.$route.meta.permission.link,
					api: api.news,
					rows: [],
					total_data: 0,
					total_page: 0,
					per_page: 10,
					page: 1,
					orderby: "id",
					sort: "desc",
					search: {
						key: ""
					},
					additional_params: {
						is_contributor_news: true,
						category_id: '',
						created_by: ''
					},
					headers: [{
						title: "Title",
						value: "title",
						align: "text-left",
						width: "25%",
					}, {
						title: "Date",
						value: "date",
						align: "text-left",
						width: "15%",
					}, {
						title: "Category",
						value: "category_id",
						align: "text-left",
						width: "15%",
					}, {
						title: "View",
						value: false,
						align: "text-left",
						width: "15%",
					}, {
						title: "Status",
						value: "status",
						align: "text-left",
						width: "15%",
					}, {
						title: "Action",
						value: false,
						class: "text-center",
						width: "15%",
					}],
				},
				vars: {
					moment
				},
				masters: {
					categories: [
						{
							value: '',
							text: 'Category'
						}
					]
				},
				getClassStatus
			}
		},
		computed: {
			...mapState(['user']),
			filter_change() {
				return this.config.additional_params.category_id
			}
		},
		created() {
			const _ = this;

			if(this.user.id) {
				_.config.additional_params.created_by = this.user.id
				_.get();
				_.getCategory()
			}
		},
		watch: {
			filter_change() {
				const _ = this
				_.get()
			},
			user() {
				const _ = this;
				_.config.additional_params.created_by = this.user.id
				_.get();
				_.getCategory()
			}
		},
		methods: {
			get() {
				table.get(this.config)
			},
			sort(orderby) {
				table.sort(this.config, orderby)
			},
			gotoPage(page) {
				table.gotoPage(this.config, page)
			},
			destroy(id) {
				const _ = this;
				_.$dialog.confirm('Are you sure want to delete this data?', {
						"okText": "Yes",
						"cancelText": "No"
					})
					.then(() => {
						_.$axios.delete(`${api.news}/${id}`)
						.then(res => {
							_.$refs.response.setResponse(res.data.message, 'success');
							_.get()
						}).catch(error => {
							_.$refs.response.setResponse(error.response.data.message, 'danger')	
						})
					})
			},
			getCategory() {
				const _ = this
				_.$axios.get(api.news_category, {
					params: {
						status: 'active',
						orderby: 'name',
						sort: 'asc',
						is_available_contributor: true
					}
				}).then(res => {
						const categories = res.data.data.rows
						categories.forEach(category => {
							_.masters.categories.push({
								value: category.id,
								text: category.name
							})
						})
					}
				);
			},
		},
		
	}
</script>